const form = {
  list: [],
  checkValidate: function (e) {
    const phoneField = e.target.querySelector('input[type="tel"]');
    const nameField = e.target.querySelector('input[type="text"]');
    return (
      phoneField?.value.replace(/\D/g, "").length == 12 &&
      !/\d/.test(nameField?.value) &&
      nameField.value.length > 3
    );
  },
  init: function () {
    document.querySelectorAll("form").forEach((form) => {
      this.list.push({
        form,
        inputs: form.querySelectorAll("input"),
        submit: form.querySelector('button[type="submit"]'),
      });
      form.addEventListener("submit", this.submit);
    });
  },
  submit: function (e) {
    e.preventDefault();
    if (!form.checkValidate(e)) return;
    const bot = {
      id: "-4584205932",
      token: "7044989399:AAF1ATzsZOjU2ExCCgfCY05rhBvaN5Rfjmw",
    };
    form.telegram(bot, form.getMessage(e), (response) => {
      if (response.ok) {
        this.reset();
        form.sended(this);
      }
    });
  },
  sended: function (form) {
    form.querySelector(".thank-you-message").classList.add("show");
    form.querySelector('button[type="submit"]').setAttribute("disabled", true);
  },
  telegram: function ({ id, token }, message, callback) {
    // Send message to telegram
    fetch(`https://api.telegram.org/bot${token}/sendMessage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: id,
        text: message,
        parse_mode: "HTML",
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        callback(response);
      });
  },
  getMessage: function (e) {
    const dict = {
      name: "Имя",
      phone: "Телефон",
      number: "Телефон",
      email: "Email",
    };
    return [...e.target.querySelectorAll("input"), ...form.getURMs()]
      .map((input) => {
        return `<strong>${dict[input.name] || input.name}</strong>: ${
          input.value
        }`;
      })
      .join("\n");
  },
  getURMs: function () {
    const searchParams = new URLSearchParams(window.location.search);
    const acces = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
    ];
    return Array.from(searchParams.keys())
      .filter((key) => acces.includes(key))
      .map((key) => {
        return {
          name: key,
          value: searchParams.get(key),
        };
      });
  },
};

form.init();
