import { load } from "./events/load.js";
import { clickOn } from "./events/click.js";
import { intersect } from "./events/intersection.js";

load(() => {
  const video = document.querySelector("#video video");
  if (!video) return;
  clickOn("#video .viewButton, #video .viewButton *", (e) => {
    start();
  });

  ["click", "ended"].forEach((event) => {
    video.addEventListener(event, () => {
      end();
    });
  });
  intersect("#video", (e) => {
    if (!e.isIntersecting) {
      end();
    }
  });
  const start = () => {
    video.play();
    document.querySelector("#video .viewButton").classList.add("active");
  };
  const end = () => {
    video.pause();
    document.querySelector("#video .viewButton").classList.remove("active");
    video.currentTime = 0;
  };
});
